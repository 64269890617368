import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const i18n = {
  'cn': {
    back: '返回',
  },
  'en': {
    back: 'Go Back',
  },

}

export default ({ data }) => {
  const { content } = data;
  const { title, date, lang } = content.frontmatter;
  const innerHTML = content.html;
  const texts = i18n[lang];
  return (
    <Layout title={title} lang={lang}>
      <Container maxWidth="lg">
        <Box m={2} p={1}>
          <Typography variant='h5'>
            {title}
          </Typography>
          <Typography variant='subtitle1'>
            {date}
          </Typography>
          <Typography component='div'>
            <div dangerouslySetInnerHTML={{__html: innerHTML}} ></div>
          </Typography>
        </Box>
        <Box justifyContent="flex-end">
          <Box m={2} p={2}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                window.history.back()
              }}
            >
              {texts.back}
            </Button>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($id: String) {
    content: markdownRemark(id: { eq: $id }) {
      frontmatter {
        date
        title
        lang
      }
      html
    }
  }
`;
